<template>
  <v-btn
      v-bind="VBtnProps"
      :href="link"
      target="_blank"
      :disabled="!link"
  >
    <template v-slot:prepend>
      <v-img :src="iconUrl" width="44" />
    </template>
    {{ data.title || 'Viber' }}
  </v-btn>
</template>
<script>
import _ from 'lodash';
import iconUrl from '@account/assets/icons/messengers/viber.svg';
export default {
  name: 'ViberBtn',
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      iconUrl,
    };
  },
  computed: {
    VBtnProps() {
      return _.merge({}, this.theme.VBtn, this.theme.ViberVBtn);
    },
    link() {
      if (this.data.chatType === 'channel' && this.data.channel) {
        return 'viber://pa?chatURI=' + encodeURIComponent(this.data.channel);
      } else if (this.data.chatType === 'phone' && this.data.phone) {
        return 'viber://chat?number=' + encodeURIComponent(this.data.phone);
      }
      return null;
    }
  },
}
</script>