import api from './api';

export default {
    /**
     * Create back call.
     *
     * @return Promise<Object>
     */
    createBackCall(account, data) {
        return api
            .post('/api/accounts/' + account + '/back-calls', data)
            .then(response => response.data);
    },

}