/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

// Composables
import { createVuetify } from 'vuetify'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import i18n from "@account/plugins/i18n.js";
import { useI18n } from 'vue-i18n'

const themeData = window.themeData;


const lightTheme = {
    dark: false,
    colors: themeData.colors,
    variables: {
    }
}

const darkTheme = {
    dark: true,
    colors: themeData.colors,
    variables: {
    }
}
export default createVuetify({
    locale: {
        adapter: createVueI18nAdapter({
            i18n,
            useI18n
        }),
    },
    defaults: themeData.defaults,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        defaultTheme: themeData.themeType === 'light' ? 'lightTheme' : 'darkTheme',
        themes: {
            lightTheme,
            darkTheme,
        },
    },
    display: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 600,
            sm: 800,
            md: 1366,
            lg: 1920,
            xl: 1920,
        },
    },
})
