<template>
  <v-form
      ref="contactForm"
      class="mx-4"
  >
    <span class="font-weight-bold">{{ $t('blocks.appointment.yourContactDetails') }}</span>
    <v-text-field
        v-model="contactData.name"
        :label="$t('blocks.appointment.yourName')"
        :rules="rules.nameValidation"
        :error-messages="errors.name"
        variant="filled"
    />

    <v-phone-input
        v-model="contactData.phone"
        ref="phone"
        :all-countries="allCountries"
        :rules="rules.phoneValidation"
        :label="$t('blocks.appointment.phoneNumber')"
        :country-label="$t('blocks.appointment.country')"
        default-country="KZ"
        country-icon-mode="svg"
        :invalid-message="$t('blocks.appointment.invalidPhoneMessage')"
        :error-messages="errors.phone"
    />
  </v-form>
</template>

<script>
import {compose, ruleRequired} from "@account/utils/validationRules.js";
import parseErrorsMixins from "@account/mixins/parseErrorsMixins.js";

export default {
  name: 'Contact',
  mixins: [parseErrorsMixins],
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactData: this.contact,
    };
  },
  computed: {
    allCountries() {
      return [
        {
          name: 'Казахстан',
          iso2: 'KZ',
          dialCode: 7
        },
        {
          name: 'Россия',
          iso2: 'RU',
          dialCode: 7
        },
      ];
    },
    rules() {
      return compose({
        nameValidation: [
          ruleRequired(),
        ],
        phoneValidation: [
          ruleRequired(),
        ],
      });
    },
  },
  methods: {
    async isValid() {
      const result = await this.$refs.contactForm.validate();
      return result.valid;
    },
  },
}
</script>
