<template>
  <div>
    <template v-for="(service, index) in services">
      <service-item
          :show-choose-btn="true"
          :service="service"
          :active-service-id="activeServiceId"
          class="mb-4"
          @selectService="selectService"
      />
      <v-divider v-if="index < services.length-1"/>
    </template>
  </div>
</template>

<script>
import ServiceItem from "@account/components/blocks/Appointment/ServiceItem.vue";

export default {
  name: 'ServicesList',
  components: {ServiceItem},
  props: {
    services: {
      type: Array,
      required: true,
    },
    activeServiceId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
    };
  },
  methods: {
    selectService(service) {
      this.$emit('selectService', service)
    },
  },
}
</script>
