<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        max-width="500"
        theme="light"
    >
      <v-card>
        <v-card-title class="text-center">
          {{ $t('blocks.backCall.requestCallback') }}
        </v-card-title>
        <v-card-text class="text-center">
          {{ $t('blocks.backCall.text') }}
          <v-form ref="backCallForm">
            <v-phone-input
                v-model="phone"
                ref="phone"
                :all-countries="allCountries"
                :rules="rules.phoneValidation"
                :label="$t('blocks.backCall.phoneNumber')"
                :country-label="$t('blocks.backCall.country')"
                default-country="KZ"
                country-icon-mode="svg"
                :invalid-message="$t('blocks.backCall.invalidPhoneMessage')"
                :error-messages="errors.phone"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
            <v-btn
                variant="tonal"
                :loading="sending"
                @click="sendPhone"
            >
              {{ $t('blocks.backCall.request') }}
            </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-bind="theme.btnContainer">
      <v-btn
          v-bind="theme.VBtn"
          @click="showBackCallDialog"
      >
        <template v-slot:prepend>
          <v-icon>
            mdi-phone-in-talk-outline
          </v-icon>
        </template>
        {{ $t('blocks.backCall.backCall') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {compose, ruleRequired} from "@account/utils/validationRules.js";
import parseErrorsMixins from "@account/mixins/parseErrorsMixins.js";
import backCalls from "@account/api/backCalls.js";

export default {
  name: 'BackCall_1',
  mixins: [parseErrorsMixins],
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
    account: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      phone: null,
      sending: false,
      phoneValidity: null,
    };
  },
  computed: {
    allCountries() {
      return [
          {
            name: 'Казахстан',
            iso2: 'KZ',
            dialCode: 7
          },
          {
            name: 'Россия',
            iso2: 'RU',
            dialCode: 7
          },
      ];
    },
    rules() {
      return compose({
        phoneValidation: [
          ruleRequired(),
        ],
      });
    },
  },
  methods: {
    showBackCallDialog() {
      if (this.preview) {
        return;
      }
      this.dialog = true;
    },
    async sendPhone() {
      const result = await this.$refs.backCallForm.validate();
      if (!result.valid) {
        return;
      }
      this.sending = true;
      try {
        const data = {
          phone: this.phone,
        };
        await backCalls.createBackCall(this.account, data);
        this.phone = null;
        this.dialog = false;
      } catch (e) {
        this.parseErrorResponse(e.response);
      } finally {
        this.sending = false;
      }
    }
  }
}
</script>
