import { en } from 'vuetify/locale'
export default {
    $vuetify: {
        ...en
    },
    loading: 'Loading',
    timeFormat: 'h:mm a',
    cancel: 'Cancel',
    continue: 'Continue',
    close: 'Close',
    validation: {
        required: 'This field is required',
        email: 'E-mail must be valid',
        emailNoSpaces: 'No spaces are allowed',
        alphaNumericDash: 'Field must only contain Latin letters, numbers, hyphens, and underscores',
    },
    footer: {
        socialLinksText: 'Get connected with us on social networks:',
        madeOn: 'Made on',
    },
    blocks: {
        backCall: {
            backCall: 'Back Call',
            requestCallback: 'Request a callback',
            text: 'Leave your phone number, we will call you back:',
            request: 'Send request',
            invalidPhoneMessage: 'The "Phone Number" field is not a valid phone number (example: +7 (705) 123 4567)',
            phoneNumber: 'Phone Number',
            country: 'Country',
        },
        map: {
            noLocation: 'Location not specified',
        },
        appointment: {
            makeAppointment: 'Make an appointment',
            night: 'Night',
            morning: 'Morning',
            day: 'Day',
            evening: 'Evening',
            select: 'Select',
            more: 'more',
            hide: 'hide',
            chooseSpecialist: 'Choose a specialist',
            hour: 'h.',
            min: 'min.',
            yourName: 'Your name',
            phoneNumber: 'Phone Number',
            country: 'Country',
            invalidPhoneMessage: 'The "Phone Number" field is not a valid phone number (example: +7 (705) 123 4567)',
            chooseAnotherTime: 'The time you selected is already taken, please select another available time',
            noTimeToReserve: 'There are no available appointment times for the selected date',
            confirmAppointment: 'Confirm Appointment',
            youAreBooked: 'You are booked!',
            forService: 'You are booked for the service:',
            price: 'Price:',
            yourSpecialist: 'Your specialist:',
            date: 'Date:',
            yourTime: 'Your time:',
            yourContactDetails: 'Your contact details:',
            name: 'Name',
            phone: 'Phone'
        },
        messengers: {
            noAdded: 'No added any messengers',
        },
        socials: {
            noAdded: 'No added any social networks',
        },
        gallery: {
            noAdded: 'No images',
        },
    },
};