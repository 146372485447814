<template>
  <v-list-item
      :active="isActiveEmployee"
      :ripple="false"
      active-class="selected-employee"
  >
    <template v-slot:prepend>
      <v-avatar color="blue" size="50">
        <v-img
            v-if="employee.avatarUrl"
            :src="employee.avatarUrl"
        />
        <v-icon
            v-else
            size="30"
        >
          mdi-account-outline
        </v-icon>
      </v-avatar>
    </template>

    <template v-slot:title>
      {{ employee.name }}
    </template>
    <template v-slot:subtitle>
      {{ employee.employee_role || '' }}
    </template>

    <template v-slot:append>
      <v-btn
          color="blue"
          icon="mdi-information-outline"
          variant="text"
      ></v-btn>
    </template>

  </v-list-item>
</template>
<script>
export default {
  name: 'EmployeeItem',
  props: {
    employee: {
      type: Object,
      required: true,
    },
    isActiveEmployee: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  data() {
    return {
    };
  },
}
</script>
<style>
.selected-employee {
  background-color: #d1f1ff;
}
</style>