import { createI18n } from 'vue-i18n';
import messages from "@account/lang/messages.js";

let locale = null;
if (siteLocale) {
    locale = siteLocale;
}
if (!locale && navigator.language) {
    const languageSplited = navigator.language.split('-');
    if (languageSplited.length) {
        const language = languageSplited[0];
        if (language === 'en' || language === 'ru') {
            locale = language;
        }
    }
}
const i18n = createI18n({
    legacy: false,
    locale,
    fallbackLocale: 'en',
    messages,
});

export default i18n;