<template>
  <v-btn
      v-bind="VBtnProps"
      :href="link"
      target="_blank"
      :disabled="!link"
  >
    <template v-slot:prepend>
      <v-img :src="iconUrl" width="44"/>
    </template>
    {{ data.title || 'VKontakte' }}
  </v-btn>
</template>
<script>
import _ from 'lodash';
import iconUrl from '@account/assets/icons/socials/vkontakte.svg';
export default {
  name: 'VkontakteBtn',
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      iconUrl,
    };
  },
  computed: {
    VBtnProps() {
      return _.merge({}, this.theme.VBtn, this.theme.VkontakteVBtn);
    },
    link() {
      return this.data.url;
    }
  },
}
</script>