<template>
  <div class="text-center">
    <div>
      <v-icon size="70" color="green" class="mt-2">
        mdi-check-circle-outline
      </v-icon>
    </div>
    <div
        v-if="finishData.service"
        class="mb-4"
    >
      <span>
        {{ $t('blocks.appointment.forService') }}
      </span>
      <br />
      <span>
        <b>{{ finishData.service.title }}</b>
      </span>
    </div>

    <div
        v-if="finishData.service"
        class="mb-4"
    >
      <span>
        {{ $t('blocks.appointment.price') }}
      </span>
      <br />
      <span>
        <b>{{ formatPrice(finishData.service.price) }} ₸</b>
      </span>
    </div>

    <div
        v-if="finishData.employee"
        class="mb-4"
    >
      <span>
        {{ $t('blocks.appointment.yourSpecialist') }}
      </span>
      <br />
      <span>
        <b>{{ finishData.employee.name }}</b>
      </span>
    </div>

    <div
        v-if="formattedDate"
        class="mb-4"
    >
      <span>
        {{ $t('blocks.appointment.date') }}
      </span>
      <br />
      <span>
        <b>{{ formattedDate }}</b>
      </span>
    </div>

    <div
        v-if="finishData.time_from && finishData.time_to"
        class="mb-4"
    >
      <span>
        {{ $t('blocks.appointment.yourTime') }}
      </span>
      <br />
      <span>
        <b>{{ formatTime(finishData.time_from) }} — {{ formatTime(finishData.time_to) }}</b>
      </span>
    </div>

    <div class="mb-4">
      <span>
        {{ $t('blocks.appointment.yourContactDetails') }}
      </span>
      <br />
      <span>
        {{ $t('blocks.appointment.name') }}: <b>{{ finishData.contact_name }}</b>
      </span>
      <br />
      <span>
        {{ $t('blocks.appointment.phone') }}: <b>{{ finishData.contact_phone }}</b>
      </span>
    </div>
  </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'Finish',
  props: {
    finishData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    formattedDate() {
      if (!this.finishData.date) {
        return null;
      }
      const date = new Date(this.finishData.date);
      return date.toLocaleDateString();
    },
  },
  methods: {
    async isValid() {
      const result = await this.$refs.contactForm.validate();
      return result.valid;
    },
    formatTime(time) {
      const timeMoment = moment(time, 'HH:mm');
      return timeMoment.format(this.$t('timeFormat'));
    },
    formatPrice(price) {
      return new Intl.NumberFormat().format(price);
    },
  },
}
</script>
