<template>
  <v-footer tile class="d-flex flex-column pa-0" >
    <div
        v-if="showOverlay"
        class="footer-overlay"
        @click="clickFooterHandler()"
    >
    </div>
    <div
        v-if="socials"
        :style="footerStyles.socials"
        class="d-flex w-100 align-center justify-center px-4 py-1"
    >
      <strong v-if="!isMobile">{{ $t('footer.socialLinksText') }}</strong>

      <template
          v-for="item in socials"
          :key="'socials_' + item.social"
      >
        <a
            class="mx-2"
            :href="item.url"
            target="_blank"
        >
          <v-avatar size="30">
            <v-img :src="socialIcons[item.social]"></v-img>
          </v-avatar>
        </a>
      </template>
    </div>

    <div
        :style="footerStyles.madeOn"
        class="px-4 py-2 text-center w-100 made-on-row"
    >
      {{ $t('footer.madeOn')}} <a :href="siteMainUrl" class="site-url-link" target="_blank">Soclink.kz</a>
    </div>
  </v-footer>
</template>

<script>
import _ from 'lodash';
import youtubeIconUrl from '@account/assets/icons/socials/youtube/circle.svg';
import instagramIconUrl from '@account/assets/icons/socials/instagram/circle.svg';
import tiktokIconUrl from '@account/assets/icons/socials/tiktok/circle.svg';
import vkIconUrl from '@account/assets/icons/socials/vk/circle.svg';
export default {
  name: 'SiteFooter',
  props: {
    footerSettings: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showOverlay: false,
      footerStyles: {
        socials: {},
        madeOn: {}
      },
      socialIcons: {
        youtube: youtubeIconUrl,
        instagram: instagramIconUrl,
        tiktok: tiktokIconUrl,
        vk: vkIconUrl,
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    siteMainUrl() {
      return import.meta.env.VITE_SITE_MAIN_URL;
    },
    targetOrigin() {
      return import.meta.env.VITE_CABINET_MAIN_URL;
    },
    socials() {
      const socials = [];
      if (!this.footerSettings.socials) {
        return null;
      }
      _.forEach(this.footerSettings.socials, (url, social) => {
        if (url) {
          socials.push({
            url,
            social,
          });
        }
      });
      return socials.length ? socials : null;
    },
  },
  mounted() {
    this.footerStyles = window.themeData.footer;
    this.showOverlay = window.previewType === 'edit';
  },
  methods: {
    clickFooterHandler() {
      window.parent.postMessage({
        source: 'account-preview',
        action: 'footer-click',
        payload: {},
      }, this.targetOrigin);
    },
  }
}
</script>
<style>
.site-url-link {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}
.made-on-row {
  font-size: 14px;
}
.footer-overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.0);
  z-index: 10000;
  cursor: pointer;
}
.footer-overlay:hover {
  display: block;
  background-color: rgba(0,0,0,0.3);
}
</style>