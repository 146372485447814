<template>
  <v-list
      lines="three"
      item-props
      :disabled="disabled"
  >
    <employee-item
        v-for="employee in employees"
        :employee="employee"
        :is-active-employee="activeEmployeeId !== null && employee.id === activeEmployeeId"
        @click="clickEmployee(employee)"
    />
  </v-list>
</template>

<script>
import EmployeeItem from "@account/components/blocks/Appointment/EmployeeItem.vue";

export default {
  name: 'EmployeesList',
  components: {EmployeeItem},
  props: {
    employees: {
      type: Array,
      required: true,
    },
    activeEmployeeId: {
      type: Number,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
    };
  },
  methods: {
    clickEmployee(employee) {
      this.$emit('selectEmployee', employee);
    }
  },
}
</script>

