<template>
  <div>
    <v-expansion-panels
        v-model="datePickerExpansionPanel"
        flat
    >
      <v-expansion-panel
          value="datepicker"
          :class="[!selectedDate || times === null ? 'hide-title' : 'date-picker-expansion-title']"
      >
        <template v-slot:title>
          <v-icon class="mr-2">
            mdi-calendar-check-outline
          </v-icon>
          <span v-if="selectedDate" class="title">{{ selectedDate.toLocaleDateString() }}</span>
        </template>
        <template v-slot:text>
          <v-locale-provider
              :locale="$vuetify.locale.current"
          >
            <v-date-picker
                v-model="selectedDate"
                width="100%"
                hide-header
                landscape
                :show-adjacent-months="true"
                :allowed-dates="allowedDates"
                @update:model-value="setDate"
            >
            </v-date-picker>
          </v-locale-provider>
        </template>
        
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="times !== null">
      <v-expansion-panels
          v-if="times.length"
          v-model="expansionPanelsValues"
          flat
          multiple
          class="times-expansion-panels"
      >
        <template v-for="(dayPartTimes, dayPart) in timesByDayParts">
          <v-expansion-panel
              v-if="dayPartTimes.length"
              :title="$t('blocks.appointment.' + dayPart)"
              :value="dayPart"
          >
            <template v-slot:text>
              <v-chip
                  v-for="time in dayPartTimes"
                  :color="selectedTime === time ? 'success' : undefined"
                  class="mr-2 mb-2"
                  @click="setTime(time)"
              >
                {{ formatTime(time) }}
              </v-chip>
            </template>
          </v-expansion-panel>
        </template>
        
      </v-expansion-panels>
      <v-alert
          v-else
          variant="tonal"
          type="warning"
      >
        {{ $t('blocks.appointment.noTimeToReserve') }}
      </v-alert>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: 'Schedule',
  props: {
    date: {
      type: String,
      required: false,
      default: null,
    },
    time: {
      type: String,
      required: false,
      default: null,
    },
    dateTimes: {
      type: Object,
      required: false,
      default: null,
    },
    forwardDays: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedDate: this.date ? new Date(this.date) : null,
      selectedTime: this.time,
      datePickerExpansionPanel: undefined,
      expansionPanelsValues: ['night', 'morning', 'day', 'evening'],
    };
  },
  computed: {
    formattedDate() {
      let formattedDate = null;
      if (this.selectedDate) {
        formattedDate = moment(this.selectedDate).format('YYYY-MM-DD');
      }
      return formattedDate;
    },
    times() {
      if (!this.selectedDate) {
        return null;
      }
      if (this.dateTimes) {
        if (Object.prototype.hasOwnProperty.call(
            this.dateTimes,
            this.formattedDate,
        ) && this.dateTimes[this.formattedDate].length > 0) {
          return this.dateTimes[this.formattedDate];
        } else {
          return [];
        }
      } else {
        return null;
      }
    },
    timesByDayParts() {
      const dayParts = {
        night: [],
        morning: [],
        day: [],
        evening: [],
      };
      if (this.times) {
        this.times.forEach(timeString => {
          const time = moment(timeString, 'HH:mm');
          if (time.isBetween(moment('00:00', 'HH:mm'), moment('05:59', 'HH:mm'), undefined, '[)')) {
            dayParts.night.push(timeString);
          } else if (time.isBetween(moment('06:00', 'HH:mm'), moment('11:59', 'HH:mm'), undefined, '[)')) {
            dayParts.morning.push(timeString);
          } else if (time.isBetween(moment('12:00', 'HH:mm'), moment('17:59', 'HH:mm'), undefined, '[)')) {
            dayParts.day.push(timeString);
          } else {
            dayParts.evening.push(timeString);
          }
        });
      }
      return dayParts;
    }
  },
  mounted() {
    this.datePickerExpansionPanel = this.selectedDate && this.times !== null ? undefined : 'datepicker';
  },
  watch: {
    time() {
      this.selectedTime = this.time;
    },
    selectedDate() {
      this.$emit('update:date', this.formattedDate);
    },
    selectedTime() {
      this.$emit('update:time', this.selectedTime);
    },
  },
  methods: {
    allowedDates(value) {
      let formattedDate = moment(value).format('YYYY-MM-DD');
      if (this.dateTimes === null) {
        const today = moment().startOf('day');
        if (moment(value).isSame(today, 'day')) {
          return true;
        } else {
          let maxDate = moment().add(this.forwardDays, 'days');
          return moment(value).isAfter(today) && moment(value).isSameOrBefore(maxDate, 'day');
        }
      }
      if (this.dateTimes && Object.prototype.hasOwnProperty.call(
          this.dateTimes,
          formattedDate,
      )) {
        const availableDate = this.dateTimes[formattedDate];
        return availableDate && availableDate.length > 0;
      }
      return false;
    },
    setDate() {
      if (this.times !== null) {
        this.datePickerExpansionPanel = undefined;
      }
      this.selectedTime = null;
    },
    setTime(time) {
      this.selectedTime = time;
    },
    formatTime(time) {
      const timeMoment = moment(time, 'HH:mm');
      return timeMoment.format(this.$t('timeFormat'));
    }
  },
}
</script>
<style>
  .hide-title .v-expansion-panel-title {
    display: none;
  }
  .date-picker-expansion-title span.title {
    font-weight: bold;
  }
  .times-expansion-panels .v-expansion-panel-text__wrapper {
    padding-bottom: 0px;
  }
</style>