<template>
  <div>
    <v-row
        no-gutters
        class="justify-center mb-1"
    >
      <v-avatar
          v-if="data.avatarUrl"
          v-bind="theme.VAvatar"
      >
        <v-img
            :alt="data.title"
            :src="data.avatarUrl"
        />
      </v-avatar>
      <v-icon
          v-else
          v-bind="theme.VIcon"
      />
    </v-row>
    <v-row
        v-if="data.title || data.subtitle"
        class="justify-center"
        no-gutters
    >
      <v-sheet
          v-bind="theme.VSheet"
      >
        <v-list-item
            v-bind="theme.VListItem"
        >
          <template v-slot:title>
            <span v-bind="theme.VListItemTitle">
              {{ data.title }}
            </span>
          </template>
          <template v-slot:subtitle>
            <span v-bind="theme.VListItemSubtitle">
              {{ data.subtitle }}
            </span>
          </template>
        </v-list-item>
      </v-sheet>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Avatar_1',
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
    defaults: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
    };
  },
}
</script>
