
import _ from 'lodash';
import Avatar_1 from "@account/components/blocks/Avatar/Avatar_1.vue";
import Divider_1 from "@account/components/blocks/Divider/Divider_1.vue";
import Text_1 from "@account/components/blocks/Text/Text_1.vue";
import BackCall_1 from "@account/components/blocks/BackCall/BackCall_1.vue";
import Map_1 from "@account/components/blocks/Map/Map_1.vue";
import Appointment_1 from "@account/components/blocks/Appointment/Apointment_1.vue";
import Messengers_1 from "@account/components/blocks/Messengers/Messengers_1.vue";
import Socials_1 from "@account/components/blocks/Socials/Socials_1.vue";
import Gallery_1 from "@account/components/blocks/Gallery/Gallery_1.vue";

export default {
    components: {
        Avatar_1,
        Divider_1,
        Text_1,
        BackCall_1,
        Map_1,
        Appointment_1,
        Messengers_1,
        Socials_1,
        Gallery_1,
    },
    data() {
        return {
            pageBlocks: [],
            themeData: {},
            pageSettings: {},
        };
    },
    computed: {
        pageBlocksComputed() {
            return this.pageBlocks.map(block => {
                const blockComputed = _.cloneDeep(block);
                blockComputed.themeComputed = this.prepareTheme(block);
                return blockComputed;
            });
        },
        pageBlocksComputedById() {
            const map = {};
            this.pageBlocksComputed.forEach(block => {
                map[block.id] = block;
            });
            return map;
        },
    },
    mounted() {
        this.pageBlocks = window.pageBlocks;
        this.themeData = window.themeData;
        this.pageSettings = window.pageSettings;
    },
    methods: {
        componentExists(componentName) {
            return Object.prototype.hasOwnProperty.call(
                this.$options.components,
                componentName,
            );
        },
        prepareTheme(block) {
            const customization = block.theme;
            let theme = {};
            if (Object.prototype.hasOwnProperty.call(
                this.themeData.components,
                block.component,
            )) {
                theme = _.cloneDeep(this.themeData.components[block.component]);
            }
            
            theme = _.merge(theme, customization);
            _.forEach(theme, (value, index) => {
                if (value === null) {
                    delete theme[index];
                }
                if (typeof value === 'object') {
                    _.forEach(value, (valueNested, indexNested) => {
                        if (valueNested === null) {
                            delete value[indexNested];
                        } else if (indexNested === 'class' && typeof valueNested === 'object' && !Array.isArray(valueNested)) {
                            value[indexNested] = Object.values(valueNested);
                        }
                    });
                }
            });
            return theme;
        },
    }
}