import _ from 'lodash';
import i18n from "@account/plugins/i18n.js";

function _reportError(signature){
    console.error('Validation rule error: ' + signature + ' has missing parameter(s)');
    return [v => false || 'Validation rule error'];
}

function _trans(message) {
    return i18n.global.t(message);
}

export function compose(rules) {
    const rulesComposed = {};
    _.forEach(rules, function(arrayRules, name) {
        if (!Object.prototype.hasOwnProperty.call(
            rulesComposed,
            name,
        )) {
            rulesComposed[name] = [];
        }
        arrayRules.forEach(rulesToCompose => {
            rulesComposed[name] = rulesComposed[name].concat(rulesToCompose);
        });
    });
    return rulesComposed;
}

export function ruleRequired() {
    return [
        v => !!v || _trans('validation.required'),
    ];
}

export function ruleMin(type, num = false) {
    if (!num) {
        return _reportError('min(num)');
    }
    let test = false;
    if (type === 'string' || type === 'array') {
        return [
            v => v.length >= num || 'Field must be longer than ' + num
        ]
    } else if (type === 'number') {
        return [
            v => v >= num || 'Field must be more than ' + num
        ]
    }
    return [
        v => v || 'Invalid type passed in field'
    ]
}

export function ruleNumeric() {
    return [
        v => /^[0-9]+$/.test(v) || 'Field must be numeric',
    ]
}

export function ruleEmail() {
    return [
        v => /.+@.+/.test(v) || _trans('validation.email'),
        v => (v || '').indexOf(' ') < 0 || _trans('validation.emailNoSpaces'),
    ]
}

export function alphaNumericDash() {
    return [
        v => /^[a-zA-Z0-9-_]+$/.test(v) || _trans('validation.alphaNumericDash')
    ];
}