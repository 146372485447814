<template>
  <div
      :style="'height:' + theme.height + 'px;'"
      :class="[data.markerLatLng ? '' : 'no-map-div']"
  >
    <l-map
        v-if="data.markerLatLng"
        style="width: 100%"
        :zoom="theme.zoom"
        :center="data.markerLatLng"
        :use-global-leaflet="false"
        :key="'map_' + data.markerLatLng.lat + '_' + data.markerLatLng.lng + '_' + theme.zoom + '_' + theme.height"
    >
      <l-tile-layer :url="url" :attribution="attribution"/>
      <l-marker :lat-lng="data.markerLatLng" />
    </l-map>
    <div v-else class="text-center">
      <v-avatar size="50">
        <v-icon size="50">mdi-map-marker-alert-outline</v-icon>
      </v-avatar>
      <br />
      <span>{{ $t('blocks.map.noLocation') }}</span>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
export default {
  name: 'Map_1',
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      bounds: null
    };
  },

}
</script>
<style>
.no-map-div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}
</style>