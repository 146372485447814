<template>
  <v-card-text class="d-flex flex-column align-center justify-center">
    <div class="text-center mb-3">{{ $t('loading') }}...</div>
    <v-progress-linear
        indeterminate
        color="primary"
        class="mb-2"
    />
  </v-card-text>
</template>

<script>
export default {
  name: 'DialogLoading',

  data() {
    return {

    };
  },
}
</script>
