<template>
  <v-card
      v-bind="theme.VCard"
      theme="light"
  >
    <v-card-title
        v-if="data.title"
        v-bind="theme.VCardTitle"
    >
      {{ data.title }}
    </v-card-title>
    <v-card-text
        v-bind="theme.VCardText"
    >
      <div class="ql-snow">
        <div class="ql-editor" v-html="data.text"></div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
  name: 'Text_1',
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
}
</script>
