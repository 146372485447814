import api from './api';

export default {
    /**
     * Get appointment data.
     *
     * @return Promise<Object>
     */
    getAppointmentData(account, blockUuid) {
        return api
            .get('/api/accounts/' + account + '/appointment/blocks/' + blockUuid)
            .then(response => response.data);
    },

    /**
     * Get appointment available dates.
     *
     * @return Promise<Object>
     */
    getAvailableDates(account, blockUuid, employeeId, serviceId) {
        return api
            .get('/api/accounts/' + account + '/appointment/blocks/' + blockUuid + '/available-dates', {
                params: {
                    employeeId,
                    serviceId,
                }
            })
            .then(response => response.data);
    },
    
    /**
     * Check if time is available.
     *
     * @return Promise<Object>
     */
    isTimeAvailable(account, blockUuid, employeeId, serviceId, datetime) {
        return api
            .get('/api/accounts/' + account + '/appointment/blocks/' + blockUuid + '/check-availability', {
                params: {
                    employeeId,
                    serviceId,
                    datetime,
                }
            })
            .then(response => response.data);
    },

    /**
     * Reserve the time.
     *
     * @return Promise<Object>
     */
    reserve(account, blockUuid, employeeId, serviceId, datetime, name, phone) {
        return api
            .post('/api/accounts/' + account + '/appointment/blocks/' + blockUuid + '/reserve', {
                employeeId,
                serviceId,
                datetime,
                name,
                phone
            })
            .then(response => response.data);
    },
}