/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify';
import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import { createVPhoneInput } from 'v-phone-input'
import i18n from "@account/plugins/i18n.js";

export function registerPlugins (app) {
  const vPhoneInput = createVPhoneInput({
    countryIconMode: 'svg',
  });
  
  app.use(vuetify);
  app.use(vPhoneInput);
  app.use(i18n);
}
