<template>
  <div v-bind="theme.btnContainer">
    <template v-for="(item, index) in data.messengers" :key="'messenger_' + item.type">
      <div class="mb-3">
        <component
            v-if="componentExists(item.type)"
            v-bind:is="item.type"
            :ref="item.type + '_' + index"
            :theme="theme"
            :data="item"
            :preview="false"
        />
      </div>
    </template>
    <template v-if="preview && (!data.messengers || !data.messengers.length)">
      <div>
        <v-icon>mdi-message-text-outline</v-icon>
        Мессенджеры не добавлены
      </div>
    </template>
  </div>
</template>

<script>
import WhatsappBtn from "@account/components/blocks/Messengers/WhatsappBtn.vue";
import TelegramBtn from "@account/components/blocks/Messengers/TelegramBtn.vue";
import ViberBtn from "@account/components/blocks/Messengers/ViberBtn.vue";
import VkontakteBtn from "@account/components/blocks/Messengers/VkontakteBtn.vue";
import SkypeBtn from "@account/components/blocks/Messengers/SkypeBtn.vue";
import FacebookBtn from "@account/components/blocks/Messengers/FacebookBtn.vue";
export default {
  name: 'Messengers_1',
  components: {
    whatsapp: WhatsappBtn,
    telegram: TelegramBtn,
    viber: ViberBtn,
    vkontakte: VkontakteBtn,
    skype: SkypeBtn,
    facebook: FacebookBtn,
  },
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
    account: {
      type: String,
      required: false,
    },
  },
  methods: {
    componentExists(componentName) {
      return Object.prototype.hasOwnProperty.call(
          this.$options.components,
          componentName,
      );
    },
  }
}
</script>
