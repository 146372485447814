<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        theme="light"
        transition="dialog-bottom-transition"
        :fullscreen="isMobile"
        :max-width="isMobile ? null : 600"
    >
      <v-card>
        <v-card-actions :class="[finish ? 'justify-center' : '']">
          <v-btn
              v-if="!finish"
              :disabled="!backBtnEnabled"
              icon="mdi-arrow-left"
              size="40" class="mr-4"
              @click="previousStep"
          />
          <span v-if="!finish">
            {{ data.title || $t('blocks.appointment.makeAppointment') }}
          </span>
          <span v-if="finish" class="text-center font-weight-bold">
            {{ $t('blocks.appointment.youAreBooked') }}
          </span>
        </v-card-actions>
        <v-divider />
        <dialog-loading v-if="loading"/>
        <v-card-text
            v-else
            :class="['pl-0', 'pr-0', 'pt-0', 'appointment-content']"
        >
          
          <div v-if="currentStep && !finish">
            <service-item
                v-if="selectedService && currentStep.id !== 'service'"
                :show-choose-btn="false"
                :service="selectedService"
            />
            
            <employee-item
                v-if="selectedEmployee && currentStep.id !== 'service' && currentStep.id !== 'employee'"
                :is-active-employee="true"
                :employee="selectedEmployee"
            />
            
            <div
                v-if="selectedDate
                && currentStep.id !== 'service'
                && currentStep.id !== 'employee'
                && currentStep.id !== 'schedule'"
                class="pa-5 font-weight-bold"
            >
              <v-icon class="mr-2">
                mdi-calendar-check-outline
              </v-icon>
              <span>{{ selectedDateFormatted }}</span>
              <span v-if="selectedTime"> - {{ selectedTimeFormatted }}</span>
            </div>
            
            <v-divider
                v-if="selectedService && currentStep.id !== 'service'"
                class="mb-4"
            />

            <v-alert
                v-model="errorAlert"
                class="mb-2"
                type="warning"
                variant="tonal"
                density="compact"
                closable
            >
              {{ errorMessage }}
            </v-alert>

            
            <div v-if="currentStep.id === 'service'">
              <services-list
                  :services="services"
                  :active-service-id="selectedService ? selectedService.id : null"
                  @selectService="selectService"
              />
            </div>

            <div v-if="currentStep.id === 'employee'">
              <div class="pl-4 pr-4 pt-4">
                <b>{{ $t('blocks.appointment.chooseSpecialist') }}:</b>
              </div>
              <employees-list
                  :employees="employeesToChoose"
                  :active-employee-id="selectedEmployee ? selectedEmployee.id : null"
                  :disabled="dateTimesLoading"
                  @selectEmployee="selectEmployee"
              />
            </div>

            <div v-if="currentStep.id === 'schedule'">
              <v-alert
                  v-model="changeTimeAlert"
                  variant="tonal"
                  type="warning"
                  closable
              >
                {{ $t('blocks.appointment.chooseAnotherTime') }}
              </v-alert>
              <schedule
                  v-model:date="selectedDate"
                  v-model:time="selectedTime"
                  :date-times="dateTimes"
                  :forward-days="data.forwardDays || 30"
              />
            </div>
            <div v-if="currentStep.id === 'contact'">
              <contact
                  ref="contactForm"
                  :contact="contact"
              />
            </div>
          </div>
          <div v-if="finish">
            <finish :finish-data="finishData"/>
          </div>
          
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
              v-if="!finish"
              @click="dialog = false;"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
              v-if="!finish && currentStep && currentStep.id !== 'contact'"
              :disabled="!continueBtnEnabled"
              :loading="dateTimesLoading || checkingAvailability"
              variant="flat"
              color="green"
              @click="nextStep"
          >
            {{ $t('continue') }}
            <template v-slot:append>
              <v-icon>mdi-arrow-right</v-icon>
            </template>
          </v-btn>
          <v-btn
              v-if="!finish && currentStep && currentStep.id === 'contact'"
              :loading="submitting"
              variant="flat"
              color="green"
              @click="reserve"
          >
            {{ $t('blocks.appointment.confirmAppointment') }}
          </v-btn>
          <v-btn
              v-if="finish"
              @click="dialog = false;"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-bind="theme.btnContainer">
      <v-btn
          v-bind="theme.VBtn"
          @click="showAppointmentDialog"
      >
        <template v-slot:prepend>
          <v-icon>
            mdi-calendar-check-outline
          </v-icon>
        </template>
        {{ data.title || $t('blocks.appointment.makeAppointment') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import parseErrorsMixins from "@account/mixins/parseErrorsMixins.js";
import DialogLoading from "@account/components/DialogLoading.vue";
import appointmentApi from "@account/api/appointment.js";
import EmployeesList from "@account/components/blocks/Appointment/EmployeesList.vue";
import ServicesList from "@account/components/blocks/Appointment/ServicesList.vue";
import ServiceItem from "@account/components/blocks/Appointment/ServiceItem.vue";
import EmployeeItem from "@account/components/blocks/Appointment/EmployeeItem.vue";
import Schedule from "@account/components/blocks/Appointment/Schedule.vue";
import Contact from "@account/components/blocks/Appointment/Contact.vue";
import Finish from "@account/components/blocks/Appointment/Finish.vue";

export default {
  name: 'Appointment_1',
  components: {Finish, Contact, Schedule, EmployeeItem, ServiceItem, ServicesList, EmployeesList, DialogLoading},
  mixins: [parseErrorsMixins],
  props: {
    blockId: {
      type: String,
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
    account: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      loading: true,
      dateTimesLoading: false,
      checkingAvailability: false,
      submitting: false,
      employees: [],
      services: [],
      selectedService: null,
      selectedEmployee: null,
      selectedDate: null,
      selectedTime: null,
      contact: {
        name: null,
        phone: null,
      },
      currentStepIndex: null,
      steps: [],
      dateTimes: null,
      changeTimeAlert: false,
      finish: false,
      finishData: null,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    currentStep() {
      if (this.currentStepIndex === null) {
        return null;
      }
      return this.steps[this.currentStepIndex];
    },
    stepIndexById() {
      const map = {};
      this.steps.forEach((step, index) => {
        map[step.id] = index;
      });
      return map;
    },
    employeesToChoose() {
      const employees = [];
      this.employees.forEach(employee => {
        if (this.selectedService) {
          if (employee.services.includes(this.selectedService.id)) {
            employees.push(employee);
          }
        } else {
          employees.push(employee);
        }
      });
      return employees;
    },
    backBtnEnabled() {
      if (this.currentStepIndex > 0) {
        return true;
      }
      return false;
    },
    continueBtnEnabled() {
      if (!this.currentStep) {
        return false;
      }
      if (this.currentStep.id === 'service') {
        if (this.selectedService) {
          return true;
        }
      } else if (this.currentStep.id === 'employee') {
        if (this.selectedEmployee) {
          return true;
        }
      } else if (this.currentStep.id === 'schedule') {
        if (this.selectedDate
            && (
                (this.dateTimes === null && this.selectedTime === null)
                || (this.dateTimes && this.selectedTime)
            )
        ) {
          return true;
        }
      }
      return false;
    },
    selectedDateFormatted() {
      if (!this.selectedDate) {
        return null;
      }
      const dateToFormat = new Date(this.selectedDate);
      return dateToFormat.toLocaleDateString();
    },
    selectedTimeFormatted() {
      if (!this.selectedTime) {
        return null;
      }
      const timeMoment = moment(this.selectedTime, 'HH:mm');
      return timeMoment.format(this.$t('timeFormat'));
    },
  },
  watch: {
    selectedDate() {
      if (this.selectedDate) {
        this.changeTimeAlert = false;
      }
    },
    selectedTime() {
      if (this.selectedTime) {
        this.changeTimeAlert = false;
      }
    },
  },
  methods: {
    async showAppointmentDialog() {
      if (this.preview) {
        return;
      }
      this.loading = true;
      if (this.data.enableEmployees || this.data.enableServices) {
        const appointmentData = await appointmentApi.getAppointmentData(this.account, this.blockId);
        this.employees = appointmentData.employees;
        this.services = appointmentData.services;
      }
      this.dialog = true;
      this.currentStepIndex = null;
      this.selectedService = null;
      this.selectedEmployee = null;
      this.selectedDate = null;
      this.selectedTime = null;
      this.finish = false;
      this.finishData = null;
      
      this.contact = {
        name: null,
        phone: null,
      };
      await this.prepareSteps();
      this.loading = false;
    },
    selectEmployee(employee) {
      if (this.selectedEmployee && this.selectedEmployee.id === employee.id) {
        this.selectedEmployee = null;
        return;
      }
      this.selectedEmployee = employee;
    },
    selectService(service) {
      if (this.selectedService && this.selectedService.id === service.id) {
        this.selectedService = null;
        this.selectedEmployee = null;
        return;
      }
      this.selectedService = service;
      this.selectedEmployee = null;
    },
    async prepareSteps() {
      const steps = [];
      if (this.data.enableServices) {
        if (this.services.length === 1) {
          this.selectedService = this.services[0];
        } else if (this.services.length > 1) {
          steps.push({
            id: 'service',
          });
        }
      }
      if (this.data.enableEmployees) {
        if (this.employees.length === 1) {
          this.selectedEmployee = this.employees[0];
        } else if (this.employees.length > 1) {
          steps.push({
            id: 'employee',
          });
        }
      }
      if (this.data.enableSchedule) {
        steps.push({
          id: 'schedule',
        });
      }
      steps.push({
        id: 'contact',
      });
      this.steps = steps;
      if (this.steps[0].id === 'schedule') {
        await this.fetchAvailableDates();
      }
      this.currentStepIndex = 0;
    },
    
    async nextStep() {
      if (this.currentStepIndex+1 <= this.steps.length-1) {
        let newStepIndex = this.currentStepIndex+1;
        const newStep = this.steps[newStepIndex];
        if (newStep.id === 'schedule' ) {
          this.dateTimesLoading = true;
          await this.fetchAvailableDates();
        }
        if (newStep.id === 'contact' ) {
          if (this.selectedTime) {
            this.checkingAvailability = true;
            const isAvailable = await this.isTimeAvailable();
            if (!isAvailable) {
              this.selectedTime = null;
              this.changeTimeAlert = true;
              await this.fetchAvailableDates();
              return;
            }
          }
        }
        this.currentStepIndex = newStepIndex;
      }
    },
    previousStep() {
      const prevStepIndex = this.currentStepIndex - 1;
      if (prevStepIndex >= 0) {
        this.currentStepIndex = prevStepIndex;
      }
    },
    
    async fetchAvailableDates() {
      try {
        this.dateTimesLoading = true;
        const dateTimes = await appointmentApi.getAvailableDates(
            this.account,
            this.blockId,
            this.selectedEmployee ? this.selectedEmployee.id : null,
            this.selectedService ? this.selectedService.id : null
        );
        this.dateTimes = dateTimes ? dateTimes : null;
      } finally {
        this.dateTimesLoading = false;
      }
    },
    
    async isTimeAvailable() {
      let isAvailable = false;
      try {
        this.checkingAvailability = true;
        const result = await appointmentApi.isTimeAvailable(
            this.account,
            this.blockId,
            this.selectedEmployee ? this.selectedEmployee.id : null,
            this.selectedService ? this.selectedService.id : null,
            this.selectedDate + ' ' + this.selectedTime,
        );
        isAvailable = result.isAvailable;
      } finally {
        this.checkingAvailability = false;
      }
      return isAvailable;
    },
    
    async reserve() {
      const isValid = await this.$refs.contactForm.isValid();
      if (!isValid) {
        return;
      }
      this.hideError();
      this.submitting = true;
      try {
        let datetime = this.selectedDate;
        if (this.selectedTime) {
          datetime = datetime + ' ' + this.selectedTime;
        }
        const response = await appointmentApi.reserve(
            this.account,
            this.blockId,
            this.selectedEmployee ? this.selectedEmployee.id : null,
            this.selectedService ? this.selectedService.id : null,
            datetime,
            this.contact.name,
            this.contact.phone
        );
        this.finishData = response.data;
        this.finish = true;
      } catch (e) {
        const response = e.response;
        if (
            response.status === 409
            && response.data
            && response.data.error_code
            && response.data.error_code === 'time_is_unavailable'
        ) {
          this.selectedTime = null;
          this.changeTimeAlert = true;
          await this.fetchAvailableDates();
          this.currentStepIndex = this.stepIndexById['schedule'];
        } else {
          this.parseErrorResponse(response);
        }
      } finally {
        this.submitting = false;
      }
    }
  },
}
</script>
<style>
.appointment-content {
  overflow-y: scroll;
}
</style>
