<template>
  <v-btn
      v-bind="VBtnProps"
      :href="link"
      target="_blank"
      :disabled="!link"
  >
    <template v-slot:prepend>
      <v-img :src="iconUrl" width="44" />
    </template>
    {{ data.title || 'WhatsApp' }}
  </v-btn>
</template>
<script>
import _ from 'lodash';
import iconUrl from '@account/assets/icons/messengers/whatsapp.svg';
export default {
  name: 'WhatsappBtn',
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      iconUrl,
    };
  },
  computed: {
    VBtnProps() {
      return _.merge({}, this.theme.VBtn, this.theme.WhatsappVBtn);
    },
    link() {
      if (this.data.chatType === 'chat' && this.data.chat) {
        return 'whatsapp://chat?code=' + encodeURIComponent(this.data.chat) + (this.data.message ? '&text=' + encodeURIComponent(this.data.message) : '');
      } else if (this.data.chatType === 'phone' && this.data.phone) {
        return 'whatsapp://send?phone='+ encodeURIComponent(this.data.phone) + (this.data.message ? '&text=' + encodeURIComponent(this.data.message) : '');
      }
      return null;
    }
  },
}
</script>