<template>
  <v-card flat>
    <v-card-title>{{ service.title }}</v-card-title>
    <v-card-text :class="[minimized ? 'd-flex' : '']">
      <div
          :id="'service-description-container-' + service.id"
          :class="['service-description-container', minimized ? 'minimized': '']"
      >
        <span
            :id="'service-description-' + service.id"
            :class="['service-description', minimized ? 'minimized': '']"
        >
          {{ formatTime(service.time) }}{{ service.description ? ' — ' : '' }}{{ service.description }}
        </span>
      </div>
      <div
          v-if="minimized && showMore"
          class="service-description-more-container"
      >
        <span @click="minimized = false">{{ $t('blocks.appointment.more') }}</span>
      </div>
      <div
          v-if="!minimized"
          class="service-description-minimize-container"
      >
        <span @click="minimized = true">...{{ $t('blocks.appointment.hide') }}</span>
      </div>
      
    </v-card-text>
    <v-card-actions>
      <span class="font-weight-bold ml-2">{{ formatPrice(service.price) }} ₸</span>
      <v-spacer />
      <v-btn
          v-if="showChooseBtn"
          variant="tonal"
          :ripple="false"
          :color="activeServiceId === service.id ? 'green' : ''"
          @click="$emit('selectService', service)"
      >
        <template v-slot:prepend>
          <v-icon>mdi-check-circle-outline</v-icon>
        </template>
        {{ $t('blocks.appointment.select') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ServiceItem',
  props: {
    service: {
      type: Object,
      required: true,
    },
    showChooseBtn: {
      type: Boolean,
      required: true,
      default: true
    },
    activeServiceId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      minimized: true,
      showMore: true,
    };
  },
  mounted() {
    const container = document.getElementById('service-description-container-' + this.service.id);
    const containerSize = container.getBoundingClientRect();
    const description = document.getElementById('service-description-' + this.service.id);
    const descriptionSize = description.getBoundingClientRect()
    if (descriptionSize.width <= containerSize.width) {
      this.showMore = false;
    }
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat().format(price);
    },
    formatTime(timeInHours) {
      const hours = Math.floor(timeInHours);
      const minutes = Math.round((timeInHours - hours) * 60);
      
      let result = '';
      if (hours > 0) {
        result += `${hours} ${this.$t('blocks.appointment.hour')}`;
      }
      if (minutes > 0) {
        if (result.length > 0) {
          result += ' ';
        }
        result += `${minutes} ${this.$t('blocks.appointment.min')}`;
      }
      return result;
    },
  },
}
</script>
<style>
.service-description-container {
}
.service-description-container.minimized {
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 50px);
}

.service-description {
  white-space: pre-wrap;
}
.service-description.minimized {
  white-space: nowrap;
}

.service-description-more-container {
  width: 40px;
  margin-left: 5px;
  span {
    font-weight: bold;
    cursor: pointer;
  }
}
.service-description-minimize-container {
  span {
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
