<template>
  <div v-bind="theme.btnContainer">
    <template v-for="(item, index) in data.socials" :key="'social_' + item.type">
      <div class="mb-3">
        <component
            v-if="componentExists(item.type)"
            v-bind:is="item.type"
            :ref="item.type + '_' + index"
            :theme="theme"
            :data="item"
            :preview="false"
        />
      </div>
    </template>
    <template v-if="preview && (!data.socials || !data.socials.length)">
      <div>
        <v-icon>mdi-account-group-outline</v-icon>
        {{ $t('blocks.socials.noAdded') }}
      </div>
    </template>
  </div>
</template>

<script>
import InstagramBtn from "@account/components/blocks/Socials/InstagramBtn.vue";
import YoutubeBtn from "@account/components/blocks/Socials/YoutubeBtn.vue";
import VkontakteBtn from "@account/components/blocks/Socials/VkontakteBtn.vue";
import TiktokBtn from "@account/components/blocks/Socials/TiktokBtn.vue";
export default {
  name: 'Socials_1',
  components: {
    instagram: InstagramBtn,
    youtube: YoutubeBtn,
    vkontakte: VkontakteBtn,
    tiktok: TiktokBtn,
  },
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
    account: {
      type: String,
      required: false,
    },
  },
  methods: {
    componentExists(componentName) {
      return Object.prototype.hasOwnProperty.call(
          this.$options.components,
          componentName,
      );
    },
  }
}
</script>
