<template>
  <v-layout>
    <v-main>
      <v-container
          fluid
          class="base-container"
          :style="containerStyles"
      >
        <slot></slot>
      </v-container>
      <site-footer
          :footer-settings="pageSettings.footer || {}"
          :preview="preview"
      />
    </v-main>
  </v-layout>
</template>

<script>
import SiteFooter from "@account/components/SiteFooter.vue";

export default {
  name: 'Layout',
  components: {SiteFooter},
  props: {
    pageSettings: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      containerStyles: {},
    };
  },
  mounted() {
    this.initContainerStyles();
  },
  methods: {
    initContainerStyles() {
      const themeData = window.themeData;
      this.containerStyles = themeData.baseContainer;
    }
  }
}
</script>
<style>
.base-container {
  min-height: 100vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>