import {en, ru} from 'vuetify/locale'
export default {
    $vuetify: {
        ...ru
    },
    loading: 'Загрузка',
    timeFormat: 'HH:mm',
    cancel: 'Отмена',
    continue: 'Продолжить',
    close: 'Закрыть',
    validation: {
        required: 'Обязательное поле',
        email: 'Не правильный E-mail адрес',
        emailNoSpaces: 'Пробелы не разрешены',
        alphaNumericDash: 'Поле может содержать только латинские буквы, цифры, дефисы и подчеркивания',
    },
    footer: {
        socialLinksText: 'Будьте с нами на связи в соцсетях:',
        madeOn: 'Сделано на',
    },
    blocks: {
        backCall: {
            backCall: 'Обратный звонок',
            requestCallback: 'Заказать обратный звонок',
            text: 'Оставьте свой номер телефона, мы вам перезвоним:',
            request: 'Заказать',
            invalidPhoneMessage: 'Телефон должен быть действительным номером (пример: +7 (705) 123 4567)',
            phoneNumber: 'Телефон',
            country: 'Страна',
        },
        map: {
            noLocation: 'Местоположение не указано',
        },
        appointment: {
            makeAppointment: 'Онлайн запись',
            night: 'Ночь',
            morning: 'Утро',
            day: 'День',
            evening: 'Вечер',
            select: 'Выбрать',
            more: 'еще',
            hide: 'скрыть',
            chooseSpecialist: 'Выберите специалиста',
            hour: 'ч.',
            min: 'мин.',
            yourName: 'Ваше имя',
            phoneNumber: 'Телефон',
            country: 'Страна',
            invalidPhoneMessage: 'Телефон должен быть действительным номером (пример: +7 (705) 123 4567)',
            chooseAnotherTime: 'Выбранное Вами время уже занято, пожалуйста, выберите другое доступное время',
            noTimeToReserve: 'На выбранную дату нет доступного времени для записи',
            confirmAppointment: 'Записаться',
            youAreBooked: 'Вы записаны!',
            forService: 'Вы записаны на услугу:',
            price: 'Цена:',
            yourSpecialist: 'Ваш специалист:',
            date: 'Дата:',
            yourTime: 'Ваше время:',
            yourContactDetails: 'Ваши контактные данные:',
            name: 'Имя',
            phone: 'Телефон'
        },
        messengers: {
            noAdded: 'Мессенджеры не добавлены',
        },
        socials: {
            noAdded: 'Социальные сети не добавлены',
        },
        gallery: {
            noAdded: 'Нет картинок',
        },
    },
};