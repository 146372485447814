<template>
  <div style="padding-top: 13px; padding-bottom: 13px">
    <v-divider
        v-bind="theme.VDivider"
    />
  </div>
</template>

<script>
export default {
  name: 'Avatar_1',
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
}
</script>
