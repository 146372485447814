<template>
  <v-card
      v-bind="theme.VCard"
  >
    <v-card-title
        v-if="data.title"
        v-bind="theme.VCardTitle"
    >
      {{ data.title }}
    </v-card-title>
    <Carousel id="gallery" :items-to-show="1" :wrap-around="true" v-model="currentSlide">
      <Slide v-for="slide in data.images.length" :key="slide">
        <div class="carousel__item">
          <v-img :src="data.images[slide-1].imageUrl" max-width="1200" :aspect-ratio="16 / 9"/>
        </div>
      </Slide>
    </Carousel>

    <Carousel
        id="thumbnails"
        :items-to-show="4"
        :wrap-around="true"
        v-model="currentSlide"
        ref="carousel"
    >
      <Slide v-for="slide in data.images.length" :key="slide">
          <div class="carousel__item" @click="slideTo(slide - 1)">
            <v-img :src="data.images[slide-1].thumbnailUrl" :aspect-ratio="16 / 9"/>
          </div>
      </Slide>
    </Carousel>

    <template v-if="preview && (!data.images || !data.images.length)">
      <div class="text-center">
        <div><v-icon>mdi-view-gallery-outline</v-icon></div>
        {{ $t('blocks.gallery.noAdded') }}
      </div>
    </template>
  </v-card>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
  name: 'Gallery_1',
  components: {
    Carousel,
    Slide,
  },
  props: {
    theme: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val
    },
  },
}
</script>
<style>
#gallery {
  margin: 10px 0;
}
#gallery .carousel__item {
  max-height: 675px;
  margin: 0 0px;
}

#thumbnails .carousel__item {
  cursor: pointer;
  margin: 0 2px;
}

.carousel__item {
  width: 100%;
  background-color: #000000;
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-title {
  white-space: normal;
}
</style>